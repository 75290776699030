import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "./logo.svg";
import "./App.css";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px 5px;
  width: 300px;
`;

const Label = styled.label`
  color: #fff;
  margin: 10px 0;
`;

const InutWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    color: #fff;
    font-size: 12px;
  }
`;

const Infos = styled.div`
  & > p {
    color: #fff;
    font-size: 15px;
  }
  & > pre {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    max-width: 100%;
    overflow-x: scroll;
    border-radius: 3px;
  }
`;

const Button = styled.div`
  border-radius: 5px;
  background-color: ${({ isToSign }) =>
    isToSign ? "rgba(14.9%, 32.2%, 50.3%, 1)" : "rgba(47.2%, 47.2%, 47.2%, 1)"};
  color: #fff;
  padding: 10px 15px;
  margin: 15px 0;
  cursor: pointer;
  pointer-events: ${({ isToSign }) => (isToSign ? "auto" : "none")};
`;

const SignWrapper = styled.textarea`
  width: 500px;
  height: 300px;
  margin: 0 0% 50px 0%;
`;

let test = () => {
  return (
    <table width="351" cellspacing="0" cellpadding="0" border="0">
      {" "}
      <tr>
        {" "}
        <td style="vertical-align: top; text-align:left;color:#000000;font-size:12px;font-family:helvetica, arial;; text-align:left">
          {" "}
          <span>
            <span style="margin-right:5px;color:#000000;font-size:15px;font-family:helvetica, arial">
              Martin Eon
            </span>{" "}
            <span style="margin-right:5px;color:#000000;font-size:12px;font-family:helvetica, arial">
              Director
            </span>
            <br />
            <span style="margin-right:5px;color:#000000;font-size:12px;font-family:helvetica, arial">
              Alouette
            </span>
          </span>{" "}
          <br />{" "}
          <span style="font:12px helvetica, arial;">
            <a
              href="mailto:eon.martin@icloud.com"
              style="color:#3388cc;text-decoration:none;"
            >
              eon.martin@icloud.com
            </a>
          </span>{" "}
          <br />{" "}
          <span style="font:12px helvetica, arial;">
            <span style="color:#000000">0671209052</span>
          </span>{" "}
          <br />{" "}
          <table cellpadding="0" cellpadding="0" border="0">
            <tr></tr>
          </table>{" "}
        </td>{" "}
      </tr>{" "}
    </table>
  );
};

let testSign = (name, email, tel, fix, status) => {
  const style = {
    h3: `color: #000; font-family: Helvetica Neue, Arial, sans-serif; font-weight: 300; margin: 0 0 5px 0; font-size: 12px; text-decoration: none; display: inline-block; padding: 0; `,
    logo: `width: 109px; height: 24.7px; margin-left: 0; margin-bottom: 5px;`,
    insta: `width: 27px; height: 27px; margin-top: 0px; margin-left: 0;`,
    div: "",
  };
  return `
  <body>
    <meta name="color-scheme" content="light dark">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="supported-color-schemes" content="light dark">
    <meta name="x-apple-disable-message-reformatting" />
    <style>
    @media (prefers-color-scheme: dark ) {
      .light{
        display: none!important;
      }
      
      .dark{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #fff!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
      .end-point{
        color: rgba(0,0,0,0)!important;
      }
    }
    @media (prefers-color-scheme: light ) {
      .dark{
        display: none!important;
      }
      .light{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #000!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
      .end-point{
        color: rgba(0,0,0,0)!important;
      }
    }
    .end-point{
      color: rgba(0,0,0,0)!important;
    }
    </style>
    <div class="body">
      <table  width="351" cellSpacing="0" cellPadding="0" border="0">
        <tbody>
          <tr>
            <td>
              <div>
                <h3 style="${style.h3}">${name}</h3>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <a style="${style.h3}" href="tel:${tel
    .split(" ")
    .join("")}">${tel}</a>
              </div>
            </td>
          </tr>
          
              ${
                fix !== null
                  ? `<tr>
                  <td><div>
                    <a style="${style.h3}" href="tel:${fix
                      .split(" ")
                      .join("")}">${fix}</a>
                    </div> </td>
                    </tr>`
                  : ``
              }
          
              ${
                (status &&
                  `<tr>
                <td><div><h3 style="${style.h3}">${status}</h3></div></td>
                </tr>`) ||
                ``
              }
          <tr>
            <td>
              <div>
                <a style="${style.h3}" href="mailto:${email}">${email}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <br/>
              <br/>
            </td>
          </tr>
          <tr>
            <td>
              <div>
              <img class="light" style="dislpay: inline; ${
                style.logo
              }" src="http://brachfeld-paris.com/assets/images/signature/B_BRACHFELD_LOGO.png" alt="Brachfeld"/>
                <img class="dark" style="display: none; ${
                  style.logo
                }" src="http://brachfeld-paris.com/assets/images/signature/W_BRACHFELD_LOGO.png" alt="Brachfeld"/>
                
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <h3 style="${style.h3}">78 rue des Archives, 75003 PARIS</h3>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <a style="${
                  style.h3
                }" href="https://www.brachfeld.org">www.brachfeld.world</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <a style="${
                  style.h3
                }" href="https://www.instagram.com/brachfeld_/?hl=en" target="_blank">
                <img class="light" style="${
                  style.insta
                } display: inline;" src="http://brachfeld-paris.com/assets/images/signature/B_INSTA_LOGO.png" alt="Brachfeld Instagram"/>
                  <img class="dark" style="display: none; ${
                    style.insta
                  }" src="http://brachfeld-paris.com/assets/images/signature/W_INSTA%20LOGO.png" alt="Brachfeld Instagram"/>
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <p class="end-point" style="color: rgba(255,255,255,1); font-size: 1px;font-weight: 200;">•</p>
              </div>
              </td>
          </tr>
          <tr>
            <td>
              <table cellpadding="0" cellpadding="0" border="0">
                <tr><td><br/></td></tr>
                <tr><td><br/></td></tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
</body>
  `;
};

let setSign = (name, email, tel, fix, status) => {
  const style = {
    h3: `color: #000; font-family: Helvetica Neue, Arial, sans-serif; font-weight: 300; margin: 0 0 2px 0; font-size: 12px; text-decoration: none; display: inline-block; padding: 0; `,
    logo: `width: 109px; height: 24.7px; margin-left: 0; margin-bottom: 5px;`,
    insta: `width: 27px; height: 27px; margin-top: -5px; margin-left: 0;`,
    div: "",
  };
  if (status && !fix) {
    return `
    <body style="height:100% !important; margin:0 !important; padding:0 !important; width:100% !important;">
    <meta name="color-scheme" content="light dark">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="supported-color-schemes" content="light dark">
    <meta name="x-apple-disable-message-reformatting" />
    <style>
    @media (prefers-color-scheme: dark ) {
      .light{
        display: none!important;
      }
      .dark{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #fff!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
    }
    @media (prefers-color-scheme: light ) {
      .dark{
        display: none!important;
      }
      .light{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #000!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
    }
  
    </style>
    <div class="body" style="text-align: left;">
      <div>
        <h3 style="${style.h3}">${name}</h3>
      </div>
      <div>
        <a style="${style.h3}" href="tel:${tel.split(" ").join("")}">${tel}</a>
      </div>
      <div><h3 style="${style.h3}">${status}</h3></div>
      <div>
        <a style="${style.h3}" href="mailto:${email}">${email}</a>
      </div>
      <br/>
      <br/>
      <div>
        <img class="dark" style="display: none; ${
          style.logo
        }" src="http://brachfeld-paris.com/assets/images/signature/W_BRACHFELD_LOGO.png" alt="Brachfeld"/>
        <img class="light" style="dislpay: inline; ${
          style.logo
        }" src="http://brachfeld-paris.com/assets/images/signature/B_BRACHFELD_LOGO.png" alt="Brachfeld"/>
      </div>
      <div>
        <h3 style="${style.h3}">78 rue des Archives, 75003 PARIS</h3>
      </div>
      <div>
        <a style="${
          style.h3
        } margin-bottom: 0px;" href="https://brachfeld.world" target="_blank">www.brachfeld.world</a>
      </div>
      <div>
        <a style="${
          style.h3
        }" href="https://www.instagram.com/brachfeld_/?hl=en" target="_blank">
          <img class="dark" style="display: none; ${
            style.insta
          }" src="http://brachfeld-paris.com/assets/images/signature/W_INSTA%20LOGO.png" alt="Brachfeld Instagram"/>
          <img class="light" style="${
            style.insta
          } display: inline;" src="http://brachfeld-paris.com/assets/images/signature/B_INSTA_LOGO.png" alt="Brachfeld Instagram"/>
        </a>
      </div>
      <div>
        <p style="color: rgba(0,0,0,0)">•</p>
      </div>
    </div>
    <br/>
    <br/>
  </body>
    `;
  } else if (fix && !status) {
    return `
    <body style="height:100% !important; margin:0 !important; padding:0 !important; width:100% !important;">
    <meta name="color-scheme" content="light dark">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="supported-color-schemes" content="light dark">
    <meta name="x-apple-disable-message-reformatting" />
    <style>
    @media (prefers-color-scheme: dark ) {
      .light{
        display: none!important;
      }
      .dark{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #fff!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
    }
    @media (prefers-color-scheme: light ) {
      .dark{
        display: none!important;
      }
      .light{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #000!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
    }
  
    </style>
    <div class="body" style="text-align: left;">
      <div>
        <h3 style="${style.h3}">${name}</h3>
      </div>
      <div>
        <a style="${style.h3}" href="tel:${tel.split(" ").join("")}">${tel}</a>
      </div>
      <div>
        <a style="${style.h3}" href="tel:${fix.split(" ").join("")}">${fix}</a>
      </div>
      <div>
        <a style="${style.h3}" href="mailto:${email}">${email}</a>
      </div>
      <br/>
      <br/>
      <div>
        <img class="dark" style="display: none; ${
          style.logo
        }" src="http://brachfeld-paris.com/assets/images/signature/W_BRACHFELD_LOGO.png" alt="Brachfeld"/>
        <img class="light" style="dislpay: inline; ${
          style.logo
        }" src="http://brachfeld-paris.com/assets/images/signature/B_BRACHFELD_LOGO.png" alt="Brachfeld"/>
      </div>
      <div>
        <h3 style="${style.h3}">78 rue des Archives, 75003 PARIS</h3>
      </div>
      <div>
        <a style="${
          style.h3
        } margin-bottom: 0px;" href="https://brachfeld.world" target="_blank">www.brachfeld.world</a>
      </div>
      <div>
        <a style="${
          style.h3
        }" href="https://www.instagram.com/brachfeld_/?hl=en" target="_blank">
          <img class="dark" style="display: none; ${
            style.insta
          }" src="http://brachfeld-paris.com/assets/images/signature/W_INSTA%20LOGO.png" alt="Brachfeld Instagram"/>
          <img class="light" style="${
            style.insta
          } display: inline;" src="http://brachfeld-paris.com/assets/images/signature/B_INSTA_LOGO.png" alt="Brachfeld Instagram"/>
        </a>
      </div>
      <div>
        <p style="color: rgba(0,0,0,0)">•</p>
      </div>
    </div>
    <br/>
    <br/>
  </body>
    `;
  } else if (fix && status) {
    return `
    <body style="height:100% !important; margin:0 !important; padding:0 !important; width:100% !important;">
    <meta name="color-scheme" content="light dark">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="supported-color-schemes" content="light dark">
    <meta name="x-apple-disable-message-reformatting" />
    <style>
    @media (prefers-color-scheme: dark ) {
      .light{
        display: none!important;
      }
      .dark{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #fff!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
    }
    @media (prefers-color-scheme: light ) {
      .dark{
        display: none!important;
      }
      .light{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #000!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
    }
  
    </style>
    <div class="body" style="text-align: left;">
      <div>
        <h3 style="${style.h3}">${name}</h3>
      </div>
      <div>
        <a style="${style.h3}" href="tel:${tel.split(" ").join("")}">${tel}</a>
      </div>
      <div>
        <a style="${style.h3}" href="tel:${fix.split(" ").join("")}">${fix}</a>
      </div>
      <div><h3 style="${style.h3}">${status}</h3></div>
      <div>
        <a style="${style.h3}" href="mailto:${email}">${email}</a>
      </div>
      <br/>
      <br/>
      <div>
        <img class="dark" style="display: none; ${
          style.logo
        }" src="http://brachfeld-paris.com/assets/images/signature/W_BRACHFELD_LOGO.png" alt="Brachfeld"/>
        <img class="light" style="dislpay: inline; ${
          style.logo
        }" src="http://brachfeld-paris.com/assets/images/signature/B_BRACHFELD_LOGO.png" alt="Brachfeld"/>
      </div>
      <div>
        <h3 style="${style.h3}">78 rue des Archives, 75003 PARIS</h3>
      </div>
      <div>
        <a style="${
          style.h3
        } margin-bottom: 0px;" href="https://brachfeld.world" target="_blank">www.brachfeld.world</a>
      </div>
      <div>
        <a style="${
          style.h3
        }" href="https://www.instagram.com/brachfeld_/?hl=en" target="_blank">
          <img class="dark" style="display: none; ${
            style.insta
          }" src="http://brachfeld-paris.com/assets/images/signature/W_INSTA%20LOGO.png" alt="Brachfeld Instagram"/>
          <img class="light" style="${
            style.insta
          } display: inline;" src="http://brachfeld-paris.com/assets/images/signature/B_INSTA_LOGO.png" alt="Brachfeld Instagram"/>
        </a>
      </div>
      <div>
        <p style="color: rgba(0,0,0,0)">•</p>
      </div>
    </div>
    <br/>
    <br/>
  </body>
    `;
  } else {
    return `
    <body style="height:100% !important; margin:0 !important; padding:0 !important; width:100% !important;">
    <meta name="color-scheme" content="light dark">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="supported-color-schemes" content="light dark">
    <meta name="x-apple-disable-message-reformatting" />
    <style>
    @media (prefers-color-scheme: dark ) {
      .light{
        display: none!important;
      }
      .dark{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #fff!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
    }
    @media (prefers-color-scheme: light ) {
      .dark{
        display: none!important;
      }
      .light{
        display: inline!important;
        overflow: visible !important; 
        float: none !important; 
        max-height:inherit !important; 
        max-width:inherit !important; 
        line-height: auto !important; 
        margin-top:5px !important; 
        visibility:inherit !important;
      }
      .body h3, .body a{
        color: #000!important;
      }
      .body p{
        color: rgba(0,0,0,0)
      }
    }
  
    </style>
    <div class="body" style="text-align: left;">
      <div>
        <h3 style="${style.h3}">${name}</h3>
      </div>
      <div>
        <a style="${style.h3}" href="tel:${tel.split(" ").join("")}">${tel}</a>
      </div>
      <div>
        <a style="${style.h3}" href="mailto:${email}">${email}</a>
      </div>
      <br/>
      <br/>
      <div>
        <img class="dark" style="display: none; ${
          style.logo
        }" src="http://brachfeld-paris.com/assets/images/signature/W_BRACHFELD_LOGO.png" alt="Brachfeld"/>
        <img class="light" style="dislpay: inline; ${
          style.logo
        }" src="http://brachfeld-paris.com/assets/images/signature/B_BRACHFELD_LOGO.png" alt="Brachfeld"/>
      </div>
      <div>
        <h3 style="${style.h3}">78 rue des Archives, 75003 PARIS</h3>
      </div>
      <div>
        <a style="${
          style.h3
        } margin-bottom: 0px;" href="https://brachfeld.world" target="_blank">www.brachfeld.world</a>
      </div>
      <div>
        <a style="${
          style.h3
        }" href="https://www.instagram.com/brachfeld_/?hl=en" target="_blank">
          <img class="dark" style="display: none; ${
            style.insta
          }" src="http://brachfeld-paris.com/assets/images/signature/W_INSTA%20LOGO.png" alt="Brachfeld Instagram"/>
          <img class="light" style="${
            style.insta
          } display: inline;" src="http://brachfeld-paris.com/assets/images/signature/B_INSTA_LOGO.png" alt="Brachfeld Instagram"/>
        </a>
      </div>
      <div>
        <p style="color: rgba(0,0,0,0)">•</p>
      </div>
    </div>
    <br/>
    <br/>
  </body>
    `;
  }
};

let setDarkSign = (name, email, tel, fix, status) => {
  return `
  <body>
  <style>
  .body{
    text-align: left;
  }
  .logo{
    width: 109px;
  }
  .insta{
    width: 27px;
    height: 27px;
    margin-top: 5px;
  }
  .light h3, .light a {
    color: #FFF;
    font-family: Helvetica Neue;
    font-weight: 300;
    margin: 0 0 5px 0;
    font-size: 12px;
    text-decoration: none;
    display: inline-block;
  }

  </style>
  <div class="body light">
  <div>
  <h3>${name}</h3>
  </div>
  <div>
  <a href="tel:${tel.split(" ").join("")}">${tel}</a>
  </div>
    ${
      (fix &&
        `<div>  <a href="tel:${fix.split(" ").join("")}">${fix}</a></div>`) ||
      ""
    }
    ${(status && `<div><h3>${status}</h3></div>`) || ""}
  <div> <a href="mailto:${email}">${email}</a></div>
  <br/>
  <br/>
    <div> <img class="logo" src="http://brachfeld-paris.com/assets/images/signature/W_BRACHFELD_LOGO.png" alt=""/></div>
  <div><h3>78 rue des Archives, 75003 PARIS</h3></div> 
  <div> <a href="https://brachfeld.world" target="_blank">www.brachfeld.world</a></div>
  <div> <a href="https://www.instagram.com/brachfeld_/?hl=en" target="_blank">
    <img class="insta" src="http://brachfeld-paris.com/assets/images/signature/W_INSTA%20LOGO.png" alt=""/>
  </a>
  </div>
  </div>
</body>
  `;
};

let setLightSign = (name, email, tel, fix, status) => {
  return `
  <body>
  <style>
  .body{
    text-align: left;
  }
  .logo{
    width: 109px;
  }
  .insta{
    width: 27px;
    height: 27px;
    margin-top: 5px;
  }
  .dark h3, .dark a {
    color: #000;
    font-family: Helvetica Neue;
    font-weight: 300;
    margin: 0 0 5px 0;
    font-size: 12px;
    text-decoration: none;
    display: inline-block;
  }

  </style>
  <div class="body dark">
  <div>
  <h3>${name}</h3>
  </div>
  <div>
  <a href="tel:${tel.split(" ").join("")}">${tel}</a>
  </div>
    ${
      (fix &&
        `<div>  <a href="tel:${fix.split(" ").join("")}">${fix}</a></div>`) ||
      ""
    }
    ${(status && `<div><h3>${status}</h3></div>`) || ""}
  <div> <a href="mailto:${email}">${email}</a></div>
  <br/>
  <br/>
    <div> <img class="logo" src="http://brachfeld-paris.com/assets/images/signature/B_BRACHFELD_LOGO.png" alt=""/></div>
  <div><h3>78 rue des Archives, 75003 PARIS</h3></div> 
  <div> <a href="https://brachfeld.world" target="_blank">www.brachfeld.world</a></div>
  <div> <a href="https://www.instagram.com/brachfeld_/?hl=en" target="_blank">
    <img class="insta" src="http://brachfeld-paris.com/assets/images/signature/B_INSTA_LOGO.png" alt=""/>
  </a>
  </div>
  </div>
</body>
  `;
};

const TestSign = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

const App = () => {
  let [name, setName] = useState(null);
  let [tel, setTel] = useState(null);
  let [fix, setFix] = useState(null);
  let [status, setStatus] = useState(null);
  let [email, setEmail] = useState(null);
  let [sign, setSignature] = useState(null);
  let [signDark, setSignDark] = useState(null);
  let [signLight, setSignLight] = useState(null);
  let [isToSign, setIsToSign] = useState(false);

  useEffect(() => {
    console.log(isToSign);
    if (name !== null && tel !== null && email !== null) {
      setIsToSign(true);
    }
  }, [name, tel, status, email]);

  return (
    <Wrapper>
      <InutWrapper>
        <Label htmlFor="name">Nom Prénom *</Label>
        <Input
          name="name"
          placeholder="Prénom NOM"
          onChange={(e) => setName(e.target.value)}
        ></Input>
        <Label htmlFor="tel">Téléphone Portable *</Label>
        <Input
          name="tel"
          placeholder="+33 6 11 22 33 44"
          onChange={(e) => setTel(e.target.value)}
        ></Input>
        <Label htmlFor="fix">Téléphone Fixe</Label>
        <Input
          name="fix"
          placeholder="+33 1 11 22 33 44"
          onChange={(e) => setFix(e.target.value)}
        ></Input>
        <Label htmlFor="status">Rôle</Label>
        <Input
          name="status"
          placeholder="Rôle"
          onChange={(e) => setStatus(e.target.value)}
        ></Input>
        <Label htmlFor="email">Email *</Label>
        <Input
          name="email"
          placeholder="sample@brachfeld.world"
          onChange={(e) => setEmail(e.target.value)}
        ></Input>
        <p>* champs obligatoire</p>
        <Button
          isToSign={isToSign}
          onClick={() => {
            setSignature(setSign(name, email, tel, fix, status));
            setSignDark(setDarkSign(name, email, tel, fix, status));
            setSignLight(setLightSign(name, email, tel, fix, status));
          }}
        >
          {sign !== null ? "Regenerate signature" : "Generate signature"}
        </Button>
        <TestSign>
          {sign !== null && (
            <div
              style={{ padding: 30 }}
              dangerouslySetInnerHTML={{
                __html: signDark,
              }}
            ></div>
          )}

          {sign !== null && (
            <div
              style={{ padding: 30, background: "#fff" }}
              dangerouslySetInnerHTML={{
                __html: signLight,
              }}
            ></div>
          )}
        </TestSign>
        <div style={{ display: "flex" }}>
          {sign !== null && (
            <Button
              isToSign={isToSign}
              onClick={(e) => {
                let textarea = document.getElementById("textarea");
                textarea.select();
                document.execCommand("copy");
              }}
            >
              Copy Code
            </Button>
          )}
        </div>
        {sign !== null && (
          <SignWrapper
            id="textarea"
            readOnly
            onSelect={() => document.execCommand("copy")}
            value={sign}
          ></SignWrapper>
        )}
        <Infos>
          <p>Pour changer la signature d'un compte lié à iCloud:</p>
          <p>• Ouvrir le terminal</p>
          <p>
            • Copier le code ci-dessous pour accéder au dossier Signature
            iCloud:{" "}
          </p>
          <pre>
            cd ~/Library/Mobile\ Documents/com\~apple\~mail/Data/V4/Signatures
          </pre>
          <p>• Lister les signatures avec la commande ci-dessous: </p>
          <pre>ls -laht</pre>
          <p>Une liste des signatures devrait s'afficher par date: </p>
          <pre>
            drwxr-xr-x@ 9 test staff 288B 14 oct 14:16 . <br />
            -rw-r--r--@ 1 test staff 2,8K 14 oct 14:16
            23RE2F2FE2F-F2123421FE-B031-48D19E53.mailsignature <br />
            drwxr-xr-x@ 5 test staff 160B 14 oct 14:04 .. <br />
            -rw-r--r--@ 1 test staff 1,3K 12 oct 14:44 AllSignatures.plist{" "}
            <br />
            -rw-r--r--@ 1 test staff 4,9K 7 oct 14:55
            23RE2F2FE2F-B031-48D1-9E53-0A69ED1913B5.mailsignature <br />
            -rw-r--r--@ 1 test staff 858B 7 oct 14:54
            E2AF2210-23RE2F2FE2F-80B9-FB6BA82536AB.mailsignature <br />
            -rw-r--r--@ 1 test staff 8,3K 7 oct 14:54
            8CD58951-4DE2-47A7-BEBD-23RE2F2FE2F.mailsignature <br />
          </pre>
          <p>• Copier le nom du fichier a modifier</p>
          <p>Dans notre cas, prenons la derniere en date</p>
          <pre>23RE2F2FE2F-F2123421FE-B031-48D19E53.mailsignature</pre>
          <p>• Ouvrir la signature avec TextEdit avec la commande suivante</p>
          <pre>
            open -a TextEdit 23RE2F2FE2F-F2123421FE-B031-48D19E53.mailsignature
          </pre>
        </Infos>
      </InutWrapper>
    </Wrapper>
  );
};

export default App;
